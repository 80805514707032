




















import Vue from 'vue';

export default Vue.extend({
  mounted() {
    if (!this.$store.getters.IS_PRERENDERING) {
      this.$router.push({
        name: `index#${this.$root.$i18n.locale}`,
      });
    }
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
